import { Link } from "react-router-dom";

import { Button } from "../ui/button"

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card"
import { Brain, Cpu, Users, Warehouse, TestTube, Forklift } from "lucide-react"
import { Helmet } from "react-helmet";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Synaptral</title>
        <meta name="description" content="Synaptral AI offers cutting-edge AI-operated machines designed to control industrial equipment like cranes, forklifts, and excavators, ensuring safety, precision, and cost-efficiency. Explore how AI technology can revolutionize your industrial operations with Synaptral AI." />
      </Helmet>
      <div className="bg-primary text-primary-foreground py-4">
        <div className="container mx-auto">
          <p className="text-base font-medium text-center mb-2" >We are testing forklifts - <strong>JOIN NOW OUR TESTING PHASE</strong> {'\u00A0'}</p>
          
          <div className="text-center">
          <Button size="sm" className="w-full md:w-60 mb-2 md:mb-0" variant="secondary" asChild>
            <Link to="/warehouse-apply"><Warehouse className="mr-2 h-4 w-4" /> for {'\u00A0'} <strong>WAREHOUSES</strong></Link>
          </Button>
          <span className="hidden md:inline">{'\u00A0'} {'\u00A0'}or{'\u00A0'} {'\u00A0'}</span>
          <Button size="sm" className="w-full md:w-60 md:mb-0" variant="secondary" asChild>
            <Link to="/operator-apply"><Forklift className="ml-2 h-4 w-4" /> {'\u00A0'} I'm a {'\u00A0'} <strong>FORKLIFT OPERATOR</strong></Link>
          </Button>
          </div>  
        </div>
      </div>
      <main className="container mx-auto py-12">
        <section className="text-center mb-16">
          <h1 className="text-4xl font-bold mb-4">Revolutionizing Industrial Operations with AI and Remote Control</h1>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Synaptral is currently in the testing phase of our groundbreaking remote operated forklift technology. We're integrating AI-driven control systems with existing machines to maximize efficiency and safety.
          </p>
        </section>
        <section className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mb-16">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <TestTube className="mr-2" />
                Testing Phase
              </CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription>
                We're currently testing our remote operated forklift technology, paving the way for a new era in industrial operations.
              </CardDescription>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <Cpu className="mr-2" />
                AI Integration
              </CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription>
                Our AI-driven control systems seamlessly integrate with existing machines, enhancing their capabilities.
              </CardDescription>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <Users className="mr-2" />
                Remote Operation
              </CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription>
                Skilled teleoperators can control forklifts remotely, addressing the global shortage of on-site machine operators.
              </CardDescription>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <Brain className="mr-2" />
                AI "Brain"
              </CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription>
                Our AI progressively learns and automates tasks, building towards fully autonomous machine operation.
              </CardDescription>
            </CardContent>
          </Card>
        </section>
        <section className="text-center">
          <h2 className="text-3xl font-bold mb-4">Be Part of the Future of Industrial Operations</h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Experience firsthand how Synaptral's remote operated forklifts can transform your warehouse operations, increase efficiency, and reduce costs.
          </p>
          <Button size="lg" variant="outline" asChild>
            <Link to="/about">Learn More About Our Technology</Link>
          </Button>
        </section>
      </main>
    </>
  )
}