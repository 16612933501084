import React, { useState } from "react";
import logo from "../assets/logo.svg"
import { Outlet, Link } from "react-router-dom";

import { Button } from "../ui/button"
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "../ui/sheet"
import { Menu } from "lucide-react"

export default function Layout() {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className="min-h-screen bg-background text-foreground">
      <header className="container mx-auto py-6">
        {/* <nav className="flex items-center justify-between">
          <Link to="/"><img
            src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/logo-1N6o0BqwxKDQq872mcGpPHII3qCh9j.svg"
            alt="Synaptral Logo"
            className="h-8"
          /></Link>
          <div className="flex gap-4">
            <Button variant="ghost" asChild><Link to="/about">About</Link></Button>
            <Button variant="ghost" asChild><Link to="/contact">Contact</Link></Button>
            <Button asChild><Link to="/apply">join us</Link></Button>
          </div>
        </nav> */}
        <nav className="flex items-center justify-between">
          <Link to="/">
          <img
            src={logo}
            alt="Synaptral Logo"
            className="h-8"
          /></Link>
          <div className="hidden md:flex gap-4">
            <Button variant="ghost" asChild><Link to="/about">About</Link></Button>
            <Button variant="ghost" asChild><Link to="/contact">Contact</Link></Button>
            <Button asChild><Link to="/apply">Get Started</Link></Button>
          </div>
          <Sheet open={isOpen} onOpenChange={setIsOpen}>
            <SheetTrigger asChild className="md:hidden">
              <Button variant="ghost" size="icon">
                <Menu className="h-6 w-6" />
                <span className="sr-only">Toggle menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent side="right">
              <SheetHeader>
                <SheetTitle>Menu</SheetTitle>
              </SheetHeader>
              <div className="flex flex-col gap-4 mt-6">
                <Button variant="ghost" onClick={() => setIsOpen(false)} asChild><Link to="/about">About</Link></Button>
                <Button variant="ghost" onClick={() => setIsOpen(false)} asChild><Link to="/contact">Contact</Link></Button>
                <Button onClick={() => setIsOpen(false)} asChild><Link to="/apply">Get Started</Link></Button>  
              </div>
            </SheetContent>
          </Sheet>
        </nav>
      </header>
      <Outlet />
      <footer className="bg-muted py-12 mt-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <h3 className="font-bold mb-4">Navigation</h3>
              <ul className="space-y-2">
                <li><Link to="/" className="hover:underline">Home</Link></li>
                <li><Link to="/about" className="hover:underline">About</Link></li>
                <li><Link to="/apply" className="hover:underline">Get Started</Link></li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold mb-4">Services</h3>
              <ul className="space-y-2">
                <li><Link to="/warehouse-apply" className="hover:underline">For Industries</Link></li>
                <li><Link to="/operator-apply" className="hover:underline">For Operators</Link></li>
                <li><Link to="/about" className="hover:underline">Our Technology</Link></li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold mb-4">Legal</h3>
              <ul className="space-y-2">
                <li><Link to="/terms-and-conditions" rel="nofollow" className="hover:underline">Terms and Conditions</Link></li>
                <li><Link to="/privacy-policy" rel="nofollow" className="hover:underline">Privacy Policy</Link></li>
                <li><Link to="/imprint" rel="nofollow" className="hover:underline">Imprint</Link></li>
                <li><button type="button" data-cc="show-preferencesModal">Manage Cookies Consent</button></li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold mb-4">Connect</h3>
              <ul className="space-y-2">
                <li><a href="https://www.linkedin.com/company/synaptral" target="_blank" rel="noopener noreferrer" className="hover:underline">LinkedIn</a></li>
                <li><a href="https://x.com/synaptral" target="_blank" rel="noopener noreferrer" className="hover:underline">X.com</a></li>
                <li><Link to="/contact" className="hover:underline">Contact</Link></li>
              </ul>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-muted-foreground/20 text-center text-muted-foreground">
            © 2024 Synaptral. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  )
}

