
import { Helmet } from "react-helmet"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion"

export default function PrivacyPolicyPage() {

  return (
      <main className="container mx-auto py-12">
        <Helmet>
          <title>Privacy Policy - Synaptral</title>
          <meta name="robots" content="none" />
        </Helmet>
        <h1 className="text-4xl font-bold mb-8 text-center">Privacy Policy</h1>
        <p className="text-lg mb-8">
          At Synaptral, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your data in compliance with the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA).
        </p>
        <Accordion type="single" collapsible className="w-full">
          <AccordionItem value="item-1">
            <AccordionTrigger>1. Information We Collect</AccordionTrigger>
            <AccordionContent>
              <p>We collect the following types of personal information:</p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>Contact information (e.g., name, email address, phone number)</li>
                <li>Professional information (e.g., job title, company name)</li>
                <li>Technical data (e.g., IP address, browser type, device information)</li>
                <li>Usage data (e.g., how you interact with our website and services)</li>
              </ul>
              <p className="mt-4">We use Google Analytics to collect and analyze website usage data. Google Analytics may use cookies to track your interactions with our website. You can learn more about how Google uses your data at <a href="https://policies.google.com/technologies/partner-sites" className="text-primary hover:underline">https://policies.google.com/technologies/partner-sites</a>.</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-2">
            <AccordionTrigger>2. How We Use Your Information</AccordionTrigger>
            <AccordionContent>
              <p>We use your personal information for the following purposes:</p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>To provide and maintain our services</li>
                <li>To communicate with you about our products and services</li>
                <li>To improve our website and user experience</li>
                <li>To comply with legal obligations</li>
                <li>To analyze website traffic and usage patterns through Google Analytics</li>
              </ul>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-3">
            <AccordionTrigger>3. Data Sharing and Disclosure</AccordionTrigger>
            <AccordionContent>
              <p>We may share your personal information with:</p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>Service providers who assist us in operating our business</li>
                <li>Law enforcement or government agencies when required by law</li>
                <li>Business partners with your consent</li>
                <li>Google, as part of our use of Google Analytics</li>
              </ul>
              <p className="mt-2">We do not sell your personal information to third parties.</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-4">
            <AccordionTrigger>4. Your Rights Under GDPR and CCPA</AccordionTrigger>
            <AccordionContent>
              <p>You have the following rights regarding your personal information:</p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>Right to access and receive a copy of your data</li>
                <li>Right to rectify or correct inaccurate data</li>
                <li>Right to delete your data (with certain exceptions)</li>
                <li>Right to restrict or object to processing</li>
                <li>Right to data portability</li>
                <li>Right to withdraw consent at any time</li>
              </ul>
              <p className="mt-2">To exercise these rights or to request data deletion, please email us at info@synaptral.ai.</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-5">
            <AccordionTrigger>5. Data Security</AccordionTrigger>
            <AccordionContent>
              <p>We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-6">
            <AccordionTrigger>6. Cookies and Similar Technologies</AccordionTrigger>
            <AccordionContent>
              <p>We use cookies and similar tracking technologies to collect and track information about your browsing activities on our website. This includes cookies used by Google Analytics and their third party partners. You can manage your cookie preferences through our cookie consent management link on the website footer or your browser settings if it has such capabilities.</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-7">
            <AccordionTrigger>7. Changes to This Privacy Policy</AccordionTrigger>
            <AccordionContent>
              <p>Synaptral reserves the right to modify the Privacy Policy at any time without prior notice. Your continued use of the website and services after any changes indicates your acceptance of the modified Privacy Policy.</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-8">
            <AccordionTrigger>8. Contact Us</AccordionTrigger>
            <AccordionContent>
              <p>If you have any questions about this Privacy Policy or our data practices, please contact us via email: info@synaptral.ai</p>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </main>
  )
}