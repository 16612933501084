import { Helmet } from "react-helmet";
import { Button } from "../ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card"
import { ArrowRight, Warehouse, Forklift } from "lucide-react"
import { Link } from "react-router-dom";


export default function ApplyPage() {
  return (
      <main className="container mx-auto py-12">
        <Helmet>
          <title>Get Started - Synaptral</title>
          <meta name="description" content="Apply to Synaptral AI and join the forefront of AI-powered industrial innovation. Submit your application for exciting roles and help us transform the future of industrial machinery and automation." />
        </Helmet>
        <section className="text-center mb-16">
          <h1 className="text-4xl font-bold mb-4">Join Our Testing Program</h1>
          <p className="text-xl mb-8 max-w-3xl mx-auto">
            Synaptral is revolutionizing industrial operations with AI-driven remote operated machines. We are now testing forklift operations - choose your role to get started with our testing program.
          </p>
        </section>
        <section className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          <Card className="flex flex-col h-full">
            <CardHeader>
              <CardTitle className="flex items-center justify-center text-2xl">
                <Warehouse className="mr-2 h-8 w-8" />
                For Warehouses
              </CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col flex-grow">
              <CardDescription className="text-center mb-6 flex-grow">
                For warehouse owners, managers, supervisors, and recruiters looking to integrate AI-driven remote operated forklifts into their operations.
              </CardDescription>
              <Button size="lg" className="w-full" asChild>
                <Link to="/warehouse-apply">Learn more as a Warehouse <ArrowRight className="ml-2 h-4 w-4" /></Link>
              </Button>
            </CardContent>
          </Card>
          <Card className="flex flex-col h-full">
            <CardHeader>
              <CardTitle className="flex items-center justify-center text-2xl">
                <Forklift className="mr-2 h-8 w-8" />
                I'm a Forklift Operator
              </CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col flex-grow">
              <CardDescription className="text-center mb-6 flex-grow">
                For experienced forklift operators interested in becoming remote operators and being at the forefront of industrial innovation.
              </CardDescription>
              <Button size="lg" className="w-full" asChild>
                <Link to="/operator-apply">Apply as an Operator <ArrowRight className="ml-2 h-4 w-4" /></Link>
              </Button>
            </CardContent>
          </Card>
        </section>
        <section className="text-center mt-16">
          <h2 className="text-2xl font-bold mb-4">Not Sure Which Option to Choose?</h2>
          <p className="text-lg mb-8 max-w-2xl mx-auto">
            If you're interested in our technology but don't fit into either category, or if you have any questions, we're here to help.
          </p>
          <Button variant="outline" size="lg" asChild>
            <Link to="/contact">Contact Us
            <ArrowRight className="ml-2 h-4 w-4" /></Link>
          </Button>
        </section>
      </main>
  )
}