import { createContext, useState } from 'react';
import { createClient } from '@supabase/supabase-js';


export const useSupabase = () => {
    const [supabase] = useState(() => createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY));
    return supabase;
  };

export const SupabaseContext = createContext(null);

export const SupabaseProvider = ({ children }) => {
  const supabase = useSupabase();
  return (
    <SupabaseContext.Provider value={{ supabase }}>
      {children}
    </SupabaseContext.Provider>
  );
};

