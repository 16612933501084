import { Helmet } from "react-helmet";
import { Button } from "../ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card"
import { AtSign, CheckCircle, Clock, Mail } from "lucide-react"
import { Link } from "react-router-dom";

export default function ThankYouWarehousePage() {
  return (
      <main className="container mx-auto py-12">
        <Helmet>
          <title>Thank you - Synaptral</title>
          <meta name="robots" content="none" />
        </Helmet>
        <section className="text-center mb-16">
          <CheckCircle className="mx-auto h-24 w-24 text-green-500 mb-6" />
          <h1 className="text-4xl font-bold mb-4">Thank You for Your Interest!</h1>
          <p className="text-xl mb-8 max-w-3xl mx-auto">
            We've received your submission for the <strong>Synaptral's Remote Operated Forklift Testing Program</strong>. We appreciate your interest in being part of this innovative technology.
          </p>
        </section>
        <section className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto mb-16">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <Clock className="mr-2" />
                What Happens Next?
              </CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription>
                <ul className="list-disc pl-5 space-y-2">
                  <li>Our team will review your application within 3-5 business days.</li>
                  <li>If your company quafilies our current testing needs, we'll reach out to schedule a virtual meeting.</li>
                  <li>We'll provide more details about the testing process and answer any questions you may have.</li>
                </ul>
              </CardDescription>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <Mail className="mr-2" />
                Stay Connected
              </CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription>
                <p className="mb-4">Keep an eye on your email inbox for updates from us. We'll be sending important information about the testing program to the email address you provided.</p>
                <p>If you have any questions in the meantime, don't hesitate to reach out:</p>
                <p className="mt-2 text-right">
                <Link to="/contact" className="text-primary hover:underline"><AtSign className="inline mr-2" />
                  Contact us</Link>
                </p>
              </CardDescription>
            </CardContent>
          </Card>
        </section>
        <section className="text-center">
          <h2 className="text-2xl font-bold mb-4">Explore More About Synaptral</h2>
          <p className="text-lg mb-8 max-w-2xl mx-auto">
            While you wait to hear from us, learn more about our innovative technology and how we're transforming industrial operations.
          </p>
          <div className="flex justify-center gap-4">
            <Button variant="outline" size="lg" asChild>
              <Link to="/about">About Synaptral</Link>
            </Button>
          </div>
        </section>
      </main>
  )
}