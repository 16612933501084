import { Button } from "../ui/button"
import { Mail } from "lucide-react"
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function ContactThankYouPage() {
  return (
      <main className="container mx-auto py-12">
        <Helmet>
          <title>Thank you - Synaptral</title>
          <meta name="robots" content="none" />
        </Helmet>
        <section className="text-center mb-16">
          <Mail className="mx-auto h-24 w-24 text-green-500 mb-6" />
          <h1 className="text-4xl font-bold mb-4">Thank You for Your Message!</h1>
          <p className="text-xl mb-8 max-w-3xl mx-auto">
            We've received your message and we should be back to you sooner.
          </p>
        </section>
        <section className="text-center">
          <h2 className="text-2xl font-bold mb-4">Explore More About Synaptral</h2>
          <p className="text-lg mb-8 max-w-2xl mx-auto">
            While you wait to hear from us, learn more about our innovative technology and how we're transforming industrial operations.
          </p>
          <div className="flex justify-center gap-4">
            <Button variant="outline" size="lg" asChild>
              <Link to="/about">About Synaptral</Link>
            </Button>
          </div>
        </section>
      </main>
  )
}