import { useState, useContext } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { SupabaseContext } from '../providers/supabase-provider';

import { Button } from "../ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card"
import { Input } from "../ui/input"
import { Label } from "../ui/label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select"
import { Textarea } from "../ui/textarea"
import { ArrowRight, Loader, Mail, Phone } from "lucide-react"
import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '../ui/alert-dialog';
import { Helmet } from 'react-helmet';

// @todo: implement libphonenumber https://github.com/armandsalle/my-site/blob/main/src/react/phone-input/combobox.tsx | https://shadcn-phone-input-five.vercel.app/

export default function ContactPage() {
  const { supabase } = useContext(SupabaseContext);
  const navigate = useNavigate();
  const [errorDialog, setErrorDialog]=useState(false);
  const [formIsPosting, setFormIsPosting]=useState(false);
  
  const [full_name,setFullName]=useState('')
  const [email,setEmail]=useState('')
  const [phone_number, setPhoneNumber]=useState('')
  const [subject, setSubject]=useState('')
  const [message, setMessage]=useState('')

  async function handleSubmit(e){
    setFormIsPosting(true)
    e.preventDefault()
    // eslint-disable-next-line
    const {data, error} = await supabase.from('contact_form').insert({
        full_name: full_name,
        email: email,
        phone_number: phone_number,
        subject: subject,
        message: message
      });

      if (error) {
        setErrorDialog(true);
      } else {
        navigate("/contact/thank-you");
      }

      setFormIsPosting(false)
  };

  return (
    <main className="container mx-auto py-12">
        <Helmet>
          <title>Contact - Synaptral</title>
          <meta name="description" content="Contact Synaptral AI to learn more about our AI-driven solutions for industrial machinery. Reach out to discuss how we can help enhance your operations with cutting-edge technology." />
        </Helmet>
        <section className="text-center mb-16">
        <h1 className="text-4xl font-bold mb-4">Contact Us</h1>
        <p className="text-xl mb-8 max-w-3xl mx-auto">
            Have questions about our technology or interested in joining our testing program? We're here to help. Reach out to us through any of the methods below.
        </p>
        </section>
        <section className="grid lg:grid-cols-2 gap-12 mb-16">
        <div className='order-last md:order-first'>
            <h2 className="text-2xl font-bold mb-6">Get in Touch</h2>
            <div className="space-y-6">
            <Card>
                <CardHeader>
                <CardTitle className="flex items-center">
                    <Mail className="mr-2" />
                    Email Us
                </CardTitle>
                </CardHeader>
                <CardContent>
                <CardDescription>
                    <a href="mailto:info@synaptral.com" className="text-primary hover:underline">info@synaptral.com</a>
                </CardDescription>
                </CardContent>
            </Card>
            <Card>
                <CardHeader>
                <CardTitle className="flex items-center">
                    <Phone className="mr-2" />
                    Call Us
                </CardTitle>
                </CardHeader>
                <CardContent>
                <CardDescription>
                    <a href="tel:+1234567890" className="text-primary hover:underline">+1 (234) 567-890</a>
                </CardDescription>
                </CardContent>
            </Card>
            {/* <Card>
                <CardHeader>
                <CardTitle className="flex items-center">
                    <MapPin className="mr-2" />
                    Visit Us
                </CardTitle>
                </CardHeader>
                <CardContent>
                <CardDescription>
                    123 Innovation Drive<br />
                    Tech City, TC 12345<br />
                    United States
                </CardDescription>
                </CardContent>
            </Card> */}
            {/* <Card>
                <CardHeader>
                <CardTitle className="flex items-center">
                    <Globe className="mr-2" />
                    Social Media
                </CardTitle>
                </CardHeader>
                <CardContent>
                <CardDescription className="flex gap-4">
                    <a href="#" className="text-primary hover:underline">LinkedIn</a>
                    <a href="#" className="text-primary hover:underline">Twitter</a>
                    <a href="#" className="text-primary hover:underline">Facebook</a>
                </CardDescription>
                </CardContent>
            </Card> */}
            </div>
        </div>
        <div>
            <h2 className="text-2xl font-bold mb-6">Send Us a Message</h2>
            <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="space-y-2">
                <Label htmlFor="name">Full Name</Label>
                <Input id="name" placeholder="John Doe" required onChange={(e)=>{setFullName(e.target.value)}} />
            </div>
            <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input id="email" type="email" placeholder="john@example.com" required onChange={(e)=>{setEmail(e.target.value)}} />
            </div>
            <div className="space-y-2">
              <Label htmlFor="phone">Phone Number</Label>
              <Input id="phone_number" typpe="tel" placeholder="+1 (555) 555-1234" required onChange={(e)=>{setPhoneNumber(e.target.value)}} />
            </div>
            <div className="space-y-2">
                <Label htmlFor="subject">Subject</Label>
                <Select value={subject} onValueChange={(value) => {setSubject(value);}}>
                    <SelectTrigger>
                        <SelectValue placeholder="Select a subject" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="general">General Inquiry</SelectItem>
                        <SelectItem value="sales">Sales</SelectItem>
                        <SelectItem value="support">Technical Support</SelectItem>
                        <SelectItem value="partnership">Partnership Opportunities</SelectItem>
                        <SelectItem value="testing">Testing Program</SelectItem>
                    </SelectContent>
                </Select>
            </div>
            <div className="space-y-2">
                <Label htmlFor="message">Message</Label>
                <Textarea id="message" placeholder="How can we help you?" rows={5}  required onChange={(e)=>{setMessage(e.target.value)}} />
            </div>
            {!formIsPosting 
                ? <Button type="submit" size="lg" className="w-full">Send Message</Button>
                : <Button type="submit" size="lg" className="w-full" disabled><Loader /></Button>
            }

            </form>
            <AlertDialog  open={errorDialog} onOpenChange={setErrorDialog}>
                <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Something went wrong</AlertDialogTitle>
                    <AlertDialogDescription>
                    We could not receive your request, you can also send us an email: <a href="mailto:info@synaptral.ai">info@synaptral.ai</a>
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>Close</AlertDialogCancel>
                </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </div>
        </section>
        <section className="text-center">
        <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Industrial Operations?</h2>
        <p className="text-xl mb-8 max-w-2xl mx-auto">
            Join our testing program today and experience the future of remote operated forklifts with AI.
        </p>
        <Button size="lg" asChild>
            <Link to="/apply">Apply for Testing Program
            <ArrowRight className="ml-2 h-4 w-4" /></Link>
        </Button>
        </section>
    </main>
  )
}