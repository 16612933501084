import { Link } from "react-router-dom";

import { Button } from "../ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card"
import { ArrowRight, Cpu, Users,Target, Lightbulb, Zap } from "lucide-react"
import { Helmet } from "react-helmet";

export default function CalendarPage() {
  return (
    <main className="container mx-auto py-12">
      <Helmet>
        <title>About - Synaptral</title>
        <meta name="description" content="Apply to Synaptral AI and join the forefront of AI-powered industrial innovation. Submit your application for exciting roles and help us transform the future of industrial machinery and automation." />
      </Helmet>
      <section className="text-center mb-16">
          <h1 className="text-4xl font-bold mb-4">Schedule a Meeting</h1>
          <p className="text-xl text-muted-foreground">
          Let's discuss further, we will build the future together!
          </p>
      </section>
      <section className="grid md:grid-cols-1 gap-8 mb-16">
          <div className="bg-card rounded-lg shadow-lg p-4">
            <iframe
              src="https://cal.com/danilofreitas"
              width="100%"
              height="800px"
              frameBorder="0"
              title="Schedule a meeting"
              className="rounded-md"
            />
          </div>
      </section>
    </main>

  )
}