import { useState, useContext } from 'react'
import { useNavigate } from "react-router-dom";
import { SupabaseContext } from '../providers/supabase-provider';

import { Button } from "../ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card"
import { Input } from "../ui/input"
import { Label } from "../ui/label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select"
import { Textarea } from "../ui/textarea"
import { Globe, Brain, Shield, Clock, Loader } from "lucide-react"
import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '../ui/alert-dialog';
import { Helmet } from 'react-helmet';

// @todo: implement libphonenumber https://github.com/armandsalle/my-site/blob/main/src/react/phone-input/combobox.tsx | https://shadcn-phone-input-five.vercel.app/

export default function ApplyForkliftOperatorPage() {
  const { supabase } = useContext(SupabaseContext);
  const navigate = useNavigate();
  const [errorDialog, setErrorDialog]=useState(false);
  const [formIsPosting, setFormIsPosting]=useState(false);

  const [full_name,setFullName]=useState('');
  const [email,setEmail]=useState('');
  const [phone_number, setPhoneNumber]=useState('');
  const [certifications,setCertifications]=useState('');
  const [experience,setExperience]=useState('');
  const [availability,setAvailability]=useState('');
  const [location, setLocation]=useState('');
  const [reason, setReason]=useState('');

  async function handleSubmit(e){
    setFormIsPosting(true)
    e.preventDefault()
    // eslint-disable-next-line
    const {data, error} = await supabase.from('interest_list_operator').insert({
        full_name: full_name,
        email: email,
        phone_number: phone_number,
        certifications: certifications,
        experience: experience,
        availability: availability,
        location: location,
        reason: reason
      });

    if (error) {
      setErrorDialog(true);
    } else {
      navigate("/operator-apply/thank-you");
    }

    setFormIsPosting(false)
  };

  return (
    <main className="container mx-auto py-12">
      <Helmet>
        <title>For operators - Synaptral</title>
        <meta name="description" content="Apply to become an AI Operator at Synaptral AI. Join us in revolutionizing industrial equipment operations with cutting-edge AI technology. Submit your application to work with advanced AI-operated machinery." />
      </Helmet>
      <section className="text-center mb-16">
        <h1 className="text-4xl font-bold mb-4">Apply as a Remote Forklift Operator</h1>
        <p className="text-xl mb-8 max-w-3xl mx-auto">
          Join Synaptral's innovative remote forklift operation program. Use your skills to control forklifts from anywhere in the world, and be part of the future of industrial operations.
        </p>
      </section>
      <section className="grid lg:grid-cols-2 gap-12 mb-16">
        <div className='order-last md:order-first'>
          <h2 className="text-2xl font-bold mb-6">Benefits for Forklift Operators</h2>
          <div className="space-y-6">
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center">
                  <Globe className="mr-2" />
                  Work from Anywhere
                </CardTitle>
              </CardHeader>
              <CardContent>
                <CardDescription>
                  Operate forklifts remotely from the comfort of your home or any location with a stable internet connection.
                </CardDescription>
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center">
                  <Brain className="mr-2" />
                  Skill Enhancement
                </CardTitle>
              </CardHeader>
              <CardContent>
                <CardDescription>
                  Develop new skills in AI-assisted operations and remote control technologies, positioning yourself at the forefront of industrial innovation.
                </CardDescription>
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center">
                  <Shield className="mr-2" />
                  Improved Safety
                </CardTitle>
              </CardHeader>
              <CardContent>
                <CardDescription>
                  Enjoy a safer working environment by operating machinery remotely, reducing the risk of on-site accidents and injuries.
                </CardDescription>
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center">
                  <Clock className="mr-2" />
                  Flexible Schedules
                </CardTitle>
              </CardHeader>
              <CardContent>
                <CardDescription>
                  Take advantage of flexible working hours and the ability to operate in different time zones, potentially increasing your earning potential.
                </CardDescription>
              </CardContent>
            </Card>
          </div>
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-6">Apply Now</h2>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="space-y-2">
              <Label htmlFor="name">Full Name</Label>
              <Input id="name" placeholder="John Smith" required onChange={(e)=>{setFullName(e.target.value)}} />
            </div>
            <div className="space-y-2">
              <Label htmlFor="email">Email</Label>
              <Input id="email" type="email" placeholder="john@example.com" required onChange={(e)=>{setEmail(e.target.value)}} />
            </div>
            <div className="space-y-2">
              <Label htmlFor="phone">Phone Number</Label>
              <Input id="phone_number" typpe="tel" placeholder="+1 (555) 555-1234" required onChange={(e)=>{setPhoneNumber(e.target.value)}} />
            </div>
            <div className="space-y-2">
              <Label htmlFor="experience">Years of Forklift Experience</Label>
              <Select value={experience} onValueChange={(value) => {setExperience(value);}}>
                <SelectTrigger>
                  <SelectValue placeholder="Select your experience" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="0-1 years">0-1 years</SelectItem>
                  <SelectItem value="1-3 years">1-3 years</SelectItem>
                  <SelectItem value="3-5">3-5 years</SelectItem>
                  <SelectItem value="3-5 years">5-10 years</SelectItem>
                  <SelectItem value="10+ years">10+ years</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <Label htmlFor="certifications">Forklift Certifications</Label>
              <Input id="certifications" placeholder="e.g., OSHA Certified, Counterbalance" onChange={(e)=>{setCertifications(e.target.value)}} />
            </div>
            <div className="space-y-2">
              <Label htmlFor="location">Current Location</Label>
              <Input id="location" placeholder="City, Country" required onChange={(e)=>{setLocation(e.target.value)}} />
            </div>
            <div className="space-y-2">
              <Label htmlFor="availability">Availability for Testing</Label>
              <Select value={availability} onValueChange={(value) => {setAvailability(value);}} required>
                <SelectTrigger>
                  <SelectValue placeholder="Select your availability" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Full-time">Full-time</SelectItem>
                  <SelectItem value="Part-time">Part-time</SelectItem>
                  <SelectItem value="Weekends only">Weekends only</SelectItem>
                  <SelectItem value="Flexible hours">Flexible hours</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <Label htmlFor="reason">Why are you interested in remote forklift operation?</Label>
              <Textarea id="reason" placeholder="Tell us about your interest in this technology and what you hope to achieve by participating in our testing program." onChange={(e)=>{setReason(e.target.value)}} required />
            </div>
            {!formIsPosting 
                ? <Button type="submit" size="lg" className="w-full">Apply Now!</Button>
                : <Button type="submit" size="lg" className="w-full" disabled><Loader /></Button>
            }
          </form>
          <AlertDialog  open={errorDialog} onOpenChange={setErrorDialog}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Something went wrong</AlertDialogTitle>
                <AlertDialogDescription>
                  We could not receive your request, you can also send us an email: <a href="mailto:info@synaptral.ai">info@synaptral.ai</a>
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </section>
    </main>

  )
}