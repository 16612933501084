import React from "react"
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function ImprintPage() {

  return (
      <main className="container mx-auto py-12 flex-grow">
        <Helmet>
          <title>Imprint - Synaptral</title>
          <meta name="robots" content="none" />
        </Helmet>
        <h1 className="text-4xl font-bold mb-8 text-center">Imprint</h1>
        <div className="max-w-2xl mx-auto">
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
            <p className="mb-2">Email: info@synaptral.ai</p>
            <p className="mb-2">Website: https://synaptral.ai</p>
          </section>
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Legal Information</h2>
            <p className="mb-2">Managing Director: Danilo Gomes de Fretas</p>
            <p className="mb-2">Company Registration Number: HRB 231165 B</p>
            <p className="mb-2">Place of Registration: District Court of Charlottenburg (Berlin - Germany)</p>
            <p className="mb-2">VAT ID: NO VAT COLLECTED <small>(Kleinunternehmer/small business) bellow 22.000 EUR revenue in the previous fiscal year or below 50.000,00 EUR in the current fiscal year.</small></p>
          </section>
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Company Information</h2>
            <p className="mb-2">Legal Name: Berbry UG (haftungsbeschränkt)</p>
            <p className="mb-2">Synaptral is a project of Berbry UG (haftungsbeschränkt)</p>
            <p className="mb-2">Pufendorfstr. 4A</p>
            <p className="mb-2">10249, Berlin, Germany</p>
          </section>
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Responsible for Content</h2>
            <p className="mb-2">Danilo Gomes de Freitas</p>
            <p className="mb-2">Address: Same as company address</p>
          </section>
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Terms and Conditions</h2>
            <p className="mb-2">Please refer to our <Link to="/terms-and-conditions">Terms and Conditions</Link> page for detailed information regarding the use of synaptral.ai.</p>
          </section>
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Privacy Policy</h2>
            <p className="mb-2">For information on how we collect, process, and protect personal data, please visit our <Link to="/privacy-policy">Privacy Policy</Link> page.</p>
          </section>
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">External Links</h2>
            <p className="mb-2">This website may contain links to external websites. We are not responsible for the content or privacy practices of external websites.</p>
          </section>
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Appointed Internal Data Protection Officer</h2>
            <p className="mb-2">Data Protection Officer: Danilo Gomes de Freitas</p>
            <p className="mb-2">Email: info@synaptral.ai</p>
            <p className="mb-2">Address: Same as company address</p>
          </section>
        </div>
      </main>
  )
}