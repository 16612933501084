import React from 'react';
import { ThemeProvider } from './theme-provider';
import { SupabaseProvider } from './supabase-provider';
import { TooltipProvider } from '../ui/tooltip';


export default function RootLayout({
    children,}) {
        return (
            <SupabaseProvider>
                <ThemeProvider>
                    <TooltipProvider>{children}</TooltipProvider>
                </ThemeProvider>
            </SupabaseProvider>
        );
    }