import React, { useEffect } from "react";

import { Routes, Route } from "react-router-dom";

import Layout from "./components/pages/layout";
import ApplyWarehousePage from "./components/pages/warehouse-apply";
import ApplyForkliftOperatorPage from "./components/pages/forklift-operator-apply";
import NotFound from "./components/pages/not-found";
import Home from "./components/pages/home";
import AboutPage from "./components/pages/about";
import ContactPage from "./components/pages/contact";
import ApplyPage from "./components/pages/apply";
import ThankYouOperatorPage from "./components/pages/thank-you-operator";
import ThankYouWarehousePage from "./components/pages/thank-you-wharehouse";
import ContactThankYouPage from "./components/pages/thank-you-contact";
import ImprintPage from "./components/pages/imprint";
import TermsAndConditionsPage from "./components/pages/terms-and-conditions";
import PrivacyPolicyPage from "./components/pages/privacy-policy";
import CalendarPage from "./components/pages/calendar";

import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import ScrollToTop from "./lib/scrollToTop";

function App() {

useEffect(() => {
    CookieConsent.run({

        categories: {
            necessary: {
                enabled: true,  // this category is enabled by default
                readOnly: true  // this category cannot be disabled
            },
            analytics: {}
        },

        language: {
            default: 'en',
            translations: {
                en: {
                    consentModal: {
                        title: 'We use cookies',
                        description: 'We use cookies and local storage for storing information necessary for this web application to work properly; and also there information stored for performance analysis and marketing campaigns. You can also check our <a href="/privacy-policy">privacy policy</a> containing the full list of data processors. ',
                        acceptAllBtn: 'Accept all',
                        acceptNecessaryBtn: 'Only Essentials',
                        showPreferencesBtn: 'Manage Individual preferences'
                    },
                    preferencesModal: {
                        title: 'Manage cookie preferences',
                        acceptAllBtn: 'Accept all',
                        acceptNecessaryBtn: 'Reject all',
                        savePreferencesBtn: 'Accept current selection',
                        closeIconLabel: 'Close modal',
                        sections: [
                            {
                                title: '',
                                description: ''
                            },
                            {
                                title: 'Only Essentials cookies',
                                description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',

                                //this field will generate a toggle linked to the 'necessary' category
                                linkedCategory: 'necessary'
                            },
                            {
                                title: 'Performance and Analytics',
                                description: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                                linkedCategory: 'analytics'
                            },
                            {
                                title: 'More information',
                                description: 'For any queries in relation to cookies and your choices, please contact info@synaptral.ai'
                            }
                        ]
                    }
                }
            }
        }
    });
}, []);


  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/contact/thank-you" element={<ContactThankYouPage />} />
          <Route path="/apply" element={<ApplyPage />} />
          <Route path="/operator-apply" element={<ApplyForkliftOperatorPage />} />
          <Route path="/operator-apply/thank-you" element={<ThankYouOperatorPage />} />
          <Route path="/warehouse-apply" element={<ApplyWarehousePage />} />
          <Route path="/warehouse-apply/thank-you" element={<ThankYouWarehousePage />} />
          <Route path="/imprint" element={<ImprintPage />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/calendar" element={<CalendarPage />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  )
}

export default App;