import { Link } from "react-router-dom";

import { Button } from "../ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card"
import { ArrowRight, Cpu, Users,Target, Lightbulb, Zap } from "lucide-react"
import { Helmet } from "react-helmet";

export default function AboutPage() {
  return (
    <main className="container mx-auto py-12">
      <Helmet>
        <title>About - Synaptral</title>
        <meta name="description" content="Apply to Synaptral AI and join the forefront of AI-powered industrial innovation. Submit your application for exciting roles and help us transform the future of industrial machinery and automation." />
      </Helmet>
      <section className="text-center mb-16">
        <h1 className="text-4xl font-bold mb-4">About Synaptral</h1>
        <p className="text-xl mb-8 max-w-3xl mx-auto">
          Synaptral is at the forefront of revolutionizing industrial operations through AI-driven control systems and remote operation technology. We're addressing global challenges in machine operation while paving the way for a more efficient and safer future.
        </p>
      </section>
      <section className="grid md:grid-cols-2 gap-8 mb-16">
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center">
              <Target className="mr-2" />
              Our Mission
            </CardTitle>
          </CardHeader>
          <CardContent>
            <CardDescription>
              To maximize efficiency and safety in industrial machine operation by seamlessly integrating AI-driven control systems with existing infrastructure, supported by skilled teleoperators. We aim to address the global shortage of machine operators while reducing business liabilities and unlocking new revenue streams.
            </CardDescription>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle className="flex items-center">
              <Lightbulb className="mr-2" />
              Our Vision
            </CardTitle>
          </CardHeader>
          <CardContent>
            <CardDescription>
              We envision a future where industrial operations are fully optimized through AI and remote operation, leading to safer workplaces, increased productivity, and new opportunities for skilled workers worldwide. Our goal is to be at the forefront of this transformation, driving innovation in AI-powered machine control and remote operation technologies.
            </CardDescription>
          </CardContent>
        </Card>
      </section>
      <section className="mb-16">
        <h2 className="text-3xl font-bold mb-8 text-center">Our Approach</h2>
        <div className="grid md:grid-cols-3 gap-6">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <Cpu className="mr-2" />
                AI Integration
              </CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription>
                We develop cutting-edge AI systems that seamlessly integrate with existing industrial machines, enhancing their capabilities and paving the way for autonomous operation.
              </CardDescription>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <Users className="mr-2" />
                Remote Operation
              </CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription>
                Our platform enables skilled teleoperators to control machines remotely, addressing the shortage of on-site operators and providing new employment opportunities.
              </CardDescription>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <Zap className="mr-2" />
                Continuous Innovation
              </CardTitle>
            </CardHeader>
            <CardContent>
              <CardDescription>
                We're constantly pushing the boundaries of what's possible, with ongoing research and development to improve our AI "Brain" and remote operation capabilities.
              </CardDescription>
            </CardContent>
          </Card>
        </div>
      </section>
      <section className="text-center mb-16">
        <h2 className="text-3xl font-bold mb-4">Join Us in Shaping the Future</h2>
        <p className="text-xl mb-8 max-w-2xl mx-auto">
          We're currently in the testing phase of our groundbreaking remote operated forklift technology. Be part of this exciting journey and help us transform industrial operations.
        </p>
        <Button size="lg" asChild>
        <Link to="/apply">Learn About Our Testing Program
          <ArrowRight className="ml-2 h-4 w-4" /></Link>
        </Button>
      </section>
    </main>

  )
}