import { useNavigate } from 'react-router-dom';


import React from "react"
import { Link } from "react-router-dom"
import { Button } from "../ui/button"
import { Home, ArrowLeft } from "lucide-react"
import { Helmet } from 'react-helmet';

export default function NotFound() {
  const navigate = useNavigate();

  return (
      <main className="container mx-auto py-12 flex-grow flex flex-col items-center justify-center text-center">
        <Helmet>
          <title>Page not found (404) - Synaptral</title>
          <meta name="robots" content="none" />
        </Helmet>
        <h1 className="text-6xl font-bold mb-4">404</h1>
        <h2 className="text-3xl font-semibold mb-6">Page Not Found</h2>
        <p className="text-xl mb-8 max-w-md">
          Oops! The page you're looking for doesn't exist. It might have been moved or deleted.
        </p>
        <div className="flex flex-col sm:flex-row gap-4">
          <Button asChild size="lg">
            <Link to="/">
              <Home className="mr-2 h-4 w-4" />
              Go to Homepage
            </Link>
          </Button>
        </div>
        <Button variant="link" className="mt-8" onClick={() => navigate(-1)}>
            <ArrowLeft className="mr-2 h-4 w-4" />
            Go Back to Previous Page
        </Button>
      </main>
  )
}