import { useState, useContext } from 'react'
import { useNavigate } from "react-router-dom";
import { SupabaseContext } from '../providers/supabase-provider';

import { Button } from "../ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card"
import { Input } from "../ui/input"
import { Label } from "../ui/label"
import { Textarea } from "../ui/textarea"
import { Warehouse, Zap, DollarSign, ShieldCheck, Loader } from "lucide-react"
import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '../ui/alert-dialog';
import { Helmet } from 'react-helmet';


// @todo: implement libphonenumber https://github.com/armandsalle/my-site/blob/main/src/react/phone-input/combobox.tsx | https://shadcn-phone-input-five.vercel.app/

export default function ApplyWarehousePage() {
  const { supabase } = useContext(SupabaseContext);
  const navigate = useNavigate();
  const [errorDialog, setErrorDialog]=useState(false);
  const [formIsPosting, setFormIsPosting]=useState(false);

  const [full_name,setFullName]=useState('')
  const [email,setEmail]=useState('')
  const [phone_number, setPhoneNumber]=useState('')
  const [company_name,setCompanyName]=useState('')
  const [role,setRole]=useState('')
  const [reason,setReason]=useState('')

  async function handleSubmit(e){
    setFormIsPosting(true)
    e.preventDefault()
    // eslint-disable-next-line
    const {data, error} = await supabase.from('interest_list_warehouse').insert({
        full_name: full_name,
        email: email,
        phone_number: phone_number,
        company_name: company_name,
        role: role,
        reason: reason
      });

      if (error) {
        setErrorDialog(true);
      } else {
        navigate("/warehouse-apply/thank-you");
      }

      setFormIsPosting(false)
  };

  return (
  
    <main className="container mx-auto py-12">
      <Helmet>
        <title>For industries - Synaptral</title>
        <meta name="description" content="Learn what warehouse can benefit with Synaptral AI and be part of transforming industrial operations with AI-driven technology. Submit your application to work in a cutting-edge environment focused on innovation and efficiency." />
      </Helmet>
      <section className="text-center mb-16">
        <h1 className="text-4xl font-bold mb-4">Apply for Warehouse Testing Program</h1>
        <p className="text-xl mb-8 max-w-3xl mx-auto">
          Transform your warehouse operations with Synaptral's AI-driven remote operated forklift technology. Join our testing program and be at the forefront of industrial innovation.
        </p>
      </section>
      <section className="grid lg:grid-cols-2 gap-12 mb-16">
        <div className='order-last md:order-first'>
          <h2 className="text-2xl font-bold mb-6">Benefits for Warehouses</h2>
          <div className="space-y-6">
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center">
                  <Zap className="mr-2" />
                  Increased Efficiency
                </CardTitle>
              </CardHeader>
              <CardContent>
                <CardDescription>
                  Our AI-driven systems optimize forklift routes and operations, significantly reducing idle time and increasing overall warehouse productivity.
                </CardDescription>
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center">
                  <DollarSign className="mr-2" />
                  Cost Reduction
                </CardTitle>
              </CardHeader>
              <CardContent>
                <CardDescription>
                  By leveraging remote operators, you can reduce labor costs while maintaining 24/7 operations, maximizing your warehouse's potential.
                </CardDescription>
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center">
                  <ShieldCheck className="mr-2" />
                  Enhanced Safety
                </CardTitle>
              </CardHeader>
              <CardContent>
                <CardDescription>
                  Our AI systems and remote operation capabilities significantly reduce the risk of accidents, creating a safer work environment for all on-site personnel.
                </CardDescription>
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center">
                  <Warehouse className="mr-2" />
                  Scalability
                </CardTitle>
              </CardHeader>
              <CardContent>
                <CardDescription>
                  Easily scale your operations up or down based on demand, without the constraints of traditional staffing models. Our technology adapts to your needs.
                </CardDescription>
              </CardContent>
            </Card>
          </div>
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-6">Get in Touch</h2>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="space-y-2">
              <Label htmlFor="name">Full Name</Label>
              <Input id="name" placeholder="John Doe" required onChange={(e)=>{setFullName(e.target.value)}} />
            </div>
            <div className="space-y-2">
              <Label htmlFor="email">Email</Label>
              <Input id="email" type="email" placeholder="john@acme.com" required onChange={(e)=>{setEmail(e.target.value)}} />
            </div>
            <div className="space-y-2">
              <Label htmlFor="phone_number">Phone Number</Label>
              <Input id="phone_number" typpe="tel" placeholder="+1 (555) 555-1234" required onChange={(e)=>{setPhoneNumber(e.target.value)}} />
            </div>
            <div className="space-y-2">
              <Label htmlFor="company_name">Company Name</Label>
              <Input id="company_name" placeholder="Acme Warehousing Inc." required onChange={(e)=>{setCompanyName(e.target.value)}} />
            </div>
            <div className="space-y-2">
              <Label htmlFor="role">Your Role</Label>
              <Input id="role" placeholder="Owner, Director, Manager, Supervisor, Recruiter, etc.." required onChange={(e)=>{setRole(e.target.value)}} />
            </div>
            <div className="space-y-2">
              <Label htmlFor="reason">Why are you interested in our testing program?</Label>
              <Textarea id="reason" name="reason" placeholder="Tell us about your current challenges and what you hope to achieve with our technology." onChange={(e)=>{setReason(e.target.value)}} />
            </div>
            {!formIsPosting 
                ? <Button type="submit" size="lg" className="w-full">I want more information!</Button>
                : <Button type="submit" size="lg" className="w-full" disabled><Loader /></Button>
            }
          </form>
          <AlertDialog  open={errorDialog} onOpenChange={setErrorDialog}>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Something went wrong</AlertDialogTitle>
                <AlertDialogDescription>
                  We could not receive your request, you can also send us an email: <a href="mailto:info@synaptral.ai">info@synaptral.ai</a>
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Close</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </section>
    </main>

  )
}