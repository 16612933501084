import { Helmet } from "react-helmet"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion"

export default function TermsAndConditionsPage() {

  return (

      <main className="container mx-auto py-12">
        <Helmet>
          <title>Terms and Conditions - Synaptral</title>
          <meta name="robots" content="none" />
        </Helmet>
        <h1 className="text-4xl font-bold mb-8 text-center">Terms and Conditions</h1>
        <p className="text-lg mb-8">
          Welcome to Synaptral. These Terms and Conditions govern your use of our website and services. By accessing or using our website, you agree to be bound by these Terms and Conditions. Please read them carefully.
        </p>
        <Accordion type="single" collapsible className="w-full">
          <AccordionItem value="item-1">
            <AccordionTrigger>1. Acceptance of Terms</AccordionTrigger>
            <AccordionContent>
              <p>By accessing or using the Synaptral website and services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree to these terms, please do not use our website or services.</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-2">
            <AccordionTrigger>2. Technology Validation and Testing</AccordionTrigger>
            <AccordionContent>
              <p>Operators participating in tests for technology validation as job seekers will not receive payment for their participation. These tests are voluntary and are designed to validate our technology and provide participants with experience in remote operation systems.</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-3">
            <AccordionTrigger>3. Corporations and Job Seekers</AccordionTrigger>
            <AccordionContent>
              <p>For corporations seeking our services or for operators seeking jobs, the terms and conditions on this website are valid. However, please note that for both cases, we provide specific contracts that will govern the relationship between Synaptral and the corporation or job seeker. These specific contracts will supersede the general terms and conditions provided on this website.</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-4">
            <AccordionTrigger>4. Privacy and Data Handling</AccordionTrigger>
            <AccordionContent>
              <p>User data collected through this website is handled in accordance with our Privacy Policy. By using our website and services, you consent to the collection, use, and sharing of your information as described in our Privacy Policy. For full details on how we handle your data, please refer to our <a href="/privacy-policy" className="text-primary hover:underline">Privacy Policy</a>.</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-5">
            <AccordionTrigger>5. Intellectual Property</AccordionTrigger>
            <AccordionContent>
              <p>All content on this website, including but not limited to text, graphics, logos, images, and software, is the property of Synaptral and is protected by copyright and other intellectual property laws. You may not use, reproduce, or distribute any content from this website without our express written permission.</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-6">
            <AccordionTrigger>6. Limitation of Liability</AccordionTrigger>
            <AccordionContent>
              <p>Synaptral shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your access to, or use of, the website and services. This includes, but is not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses.</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-7">
            <AccordionTrigger>7. Modifications to Terms</AccordionTrigger>
            <AccordionContent>
              <p>Synaptral reserves the right to modify these Terms and Conditions at any time without prior notice. Your continued use of the website and services after any changes indicates your acceptance of the modified Terms and Conditions.</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-8">
            <AccordionTrigger>8. Governing Law</AccordionTrigger>
            <AccordionContent>
              <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of the jurisdiction in which Synaptral is registered, without regard to its conflict of law provisions.</p>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-9">
            <AccordionTrigger>9. Contact Information</AccordionTrigger>
            <AccordionContent>
              <p>If you have any questions about these Terms and Conditions, please contact us via email: info@synaptral.ai</p>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </main>
  )
}